<template>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ha va m1">
        <div class="row">

            <div class="col-lg-5 col-md-4 col-sm-5 col-xs-12 ha">
                <i class="fa-solid fa-calculator"></i>
            </div>
            <div class="col-lg-7 col-md-8 col-sm-7 col-xs-12 sec">
                <h4>Spočítáme to za Vás!</h4>
                <p>V následujícím formuláři stačí vyplnit jen nezbytné údaje pro kontaktování. Pokud vyplníte podrobný formulář, mohu si udělat již lepší představu o co se zajímáte.</p>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    setup() {
        
    },
}
</script>

<style>
section{color: #6c757d;}

section i {font-size: 10rem;line-height: 41px;}
</style>